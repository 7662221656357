import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {AnyProjectJobForm} from '../models/project-job-form';
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {ProjectJobCreateRequest} from '../models/project-job-create-request';
import {ProjectJobLocationAnswer} from '../models/project-job-form-extra';
import {map} from 'rxjs/operators';
import {getFileNameFromContentDisposition} from '../utils/content-disposition';
import {Capacitor} from '@capacitor/core';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {blobToBase64} from '../utils/blob-to-base64';
import {FileOpener} from '@capacitor-community/file-opener';
import {FileUtils} from '../utils/file-utils';

export interface ProjectJobService {
    list(projectId: number, page: number, status?: string, search?: string[], sort?: string[]): Observable<PageResponse<AnyProjectJobForm>>;
    create(projectId: number, request: ProjectJobCreateRequest): Observable<AnyProjectJobForm>;
    transition(projectId: number, jobId: number, status: string): Observable<AnyProjectJobForm>;
    rejectWithObstruction(projectId: number, jobId: number, obstructionCode: string, obstructionRemark: string | null): Observable<AnyProjectJobForm>;
    nonExistingIds(projectId: number, ids: number[]): Observable<number[]>;
    saveLocationQuestionAnswer(projectId: number, jobId: number, answer: ProjectJobLocationAnswer): Observable<ProjectJobLocationAnswer>;
    generateJobCode(projectId: number): Observable<string>;
    downloadJobExport(jobId: number): Observable<HttpResponse<Blob>>;
    openJobExport(jobId: number): Promise<void>;
}

@Injectable()
export class ProjectJobServiceImpl implements ProjectJobService {
    constructor(private http: HttpClient) {
    }

    list(projectId: number, page: number, status?: string, searchCriteria?: string[], sort?: string[]): Observable<PageResponse<AnyProjectJobForm>> {
        let params = new HttpParams().set('page', '' + page);

        if (searchCriteria && searchCriteria.length > 0) {
            for(const search of searchCriteria) {
                params = params.append('search', `search:${search}`);
            }
        }
        if (status) {
            params = params.append('search', `status=${status}`);
        }

        if (sort && sort.length > 0) {
            for(const sortItem of sort) {
                params = params.append('sort', sortItem);
            }
            // Always sort by id as last sort item to ensure consistent ordering
            params.append('sort', 'id');
        }

        return this.http.get<PageResponse<AnyProjectJobForm>>(`/app-api/v1/projects/${projectId}/jobs`, {params});
    }

    create(projectId: number, request: ProjectJobCreateRequest): Observable<AnyProjectJobForm> {
        return this.http.post<AnyProjectJobForm>(`/app-api/v1/projects/${projectId}/jobs`, request);
    }

    transition(projectId: number, jobId: number, status: string) {
        return this.http.post<AnyProjectJobForm>(
            `/app-api/v1/projects/${projectId}/jobs/${jobId}/transition`,
            null,
            {params: {'status': status}}
        );
    }

    rejectWithObstruction(projectId: number, jobId: number, obstructionCode: string, obstructionRemarks: string | null) {
        return this.http.post<AnyProjectJobForm>(
            `/app-api/v1/projects/${projectId}/jobs/${jobId}/executor-obstruct`,
            {obstructionCode, obstructionRemarks}
        );
    }

    nonExistingIds(projectId: number, ids: number[]) {
        return this.http.get<number[]>(
            `/app-api/v1/projects/${projectId}/jobs/non-existing`,
            {params: {'ids': ids.join(',')}}
        );
    }

    saveLocationQuestionAnswer(projectId: number, jobId: number, answer: ProjectJobLocationAnswer) {
        return this.http.post<ProjectJobLocationAnswer>(
            `/app-api/v1/projects/${projectId}/jobs/${jobId}/location-answer`,
            answer,
        );
    }

    generateJobCode(projectId: number): Observable<string> {
        return this.http.get<{code: string}>(
            `/app-api/v1/projects/${projectId}/jobs/code`
        ).pipe(map(it => it.code));
    }

    downloadJobExport(jobId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(`/app-api/v1/job-export/${jobId}/download`, {
            responseType: 'blob',
            observe: 'response'
        });
    }

    async openJobExport(jobId: number): Promise<void> {
        const httpResponse = await firstValueFrom(this.downloadJobExport(jobId));
        const fileName = getFileNameFromContentDisposition(httpResponse.headers.get('content-disposition'), 'export.pdf');

        if (httpResponse.body !== null) {
            // Downloading as blob doesn't work on native platforms, so we use an alternative implementation
            if (Capacitor.isNativePlatform()) {
                const writtenFile = await Filesystem.writeFile({
                    directory: Directory.Cache,
                    path: fileName,
                    data: await blobToBase64(httpResponse.body),
                })
                await FileOpener.open({
                    filePath: writtenFile.uri
                })
            } else {
                FileUtils.downloadBlobAsFile(httpResponse.body, fileName);
            }
        }
    }
}
